
export const environment = {
   production: true,
   PERMISSION_API_BASE_URL: "https://portal.avangarde-software.com/",
   APP_BASE_URL: "https://portal.avangarde-software.com",
   APP_BFF_URL: "https://portal.avangarde-software.com",
   CLIENT_NAME: "avangarde-portalv2",
   API_LOGIN_REDIRECT_URL: "https://portal.avangarde-software.com/login",
   APP_DASHBOARD_URL: "/dashboard-component",
   API_LOGOUT_REDIRECT_URL: "https://portal.avangarde-software.com/logout",
   API_LOGIN_CLIENT_REDIRECT_URL: "https://portal.avangarde-software.com/loginWithClient",
   PMANAGER_BASE_URL: "https://portal.avangarde-software.com/pmanager"
};
